import React, { useEffect, useState } from 'react'
import { Button, Form, FormGroup, Input, Label, Navbar, NavbarBrand } from 'reactstrap'
import logoUCOM from '../../assets/logo/ucom-parafondoazul-ft.png'
import { loginService } from 'services/login.tsx';

const NavbarLogin = () => {
    const [usernameOrEmail, setUsernameOrEmail] = useState('');
    const [password, setPassword] = useState('')
    const [loggedIn, setLoggedIn] = useState(false);

    function onSubmit() {
        // setLoggedIn(true);
        // props.parentCallback(true);
        if (usernameOrEmail && password) {

            loginService({ usernameOrEmail, password }).then((res) => {
                console.log("Successful Login: ", res);
                if (res.data.data.role === 'ROLE_ADMIN' || res.data.data.role === 'ROLE_SELLER') {
                    window.location.href = '/admin/dashboard'
                    localStorage.setItem('id_user', res.data.data.id_usuario);
                    localStorage.setItem('usser', res.data.data.username);
                    localStorage.setItem('jwt_token', res.data.token);
                } else {
                    window.location.href = '/'
                }
            }).catch((err) => console.log(err));
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (usernameOrEmail, password) {
            onSubmit();
        } else {
            alert("La cagaste");
        }
    }
    useEffect(() => {
        console.log(usernameOrEmail);
        console.log(password);
    }, [usernameOrEmail, password]);
    return (
        <Navbar
            color="dark"
            dark
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', position: 'fixed' }}
        >
            <NavbarBrand href="/" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img
                    alt="logo"
                    src={logoUCOM}
                    style={{
                        height: 60,
                        width: 60
                    }}
                />
                <h3 style={{ marginBottom: 0 }}>ADMIN</h3>
            </NavbarBrand>
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                <Form onSubmit={handleSubmit} inline noValidate style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormGroup style={{ marginRight: '10px' }}>
                        {/* <Label for="usernameOrEmail" className="sr-only">Email Address</Label> */}
                        <Input
                            type="text"
                            name="usernameOrEmail"
                            id="usernameOrEmail"
                            placeholder="Email o Username"
                            autoComplete="off"
                            onChange={(e) => setUsernameOrEmail(e.target?.value)}
                            value={usernameOrEmail || ""}
                            required
                        />
                    </FormGroup>
                    <FormGroup style={{ marginRight: '10px' }}>
                        {/* <Label for="password" className="sr-only">Password</Label> */}
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target?.value)}
                            value={password || ""}
                            required
                        />
                    </FormGroup>
                    <Button type="submit" color="info">Entrar</Button>
                </Form>
            </div>
        </Navbar>
    )
}

export default NavbarLogin
