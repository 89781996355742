import React from 'react';
import imgUniversity from '../../assets/img/univeristy.webp';
import '../../assets/css/section-register.css'
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';

const SectionRegister = () => {
    return (
        <div className='containerSectionRegister'>
            <div style={{ position: 'relative', flex: '1' }}>
                <img
                    src={imgUniversity}
                    alt="University"
                    style={{
                        height: '100%',
                        width: '100%',
                        left: '200px',
                        objectFit: 'cover',
                        display: 'flex'


                    }}
                />
                <div className="gradientOverlay"></div>
            </div>
            <div className='formularioRegistro' style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Form style={{ width: '80%' }}>
                    <h2 style={{ marginTop: '60px', textAlign: 'center' }}>Registrate como vendedor</h2>
                    <FormGroup row>
                        <Label for="fullName" sm={2}>Nombre Completo</Label>
                        <Col sm={10}>
                            <Input type="text" name="fullName" id="fullName" placeholder="Ingresa nombre completo" required />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="email" sm={2}>Email</Label>
                        <Col sm={10}>
                            <Input type="email" name="email" id="email" placeholder="Ingresa email" required />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="password" sm={2}>Contraseña</Label>
                        <Col sm={10}>
                            <Input type="password" name="password" id="password" placeholder="Ingresa tu password" required />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="confirmPassword" sm={2}>Confirmar contraseña</Label>
                        <Col sm={10}>
                            <Input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm tu password" required />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="storeName" sm={2}>Nombre Tienda</Label>
                        <Col sm={10}>
                            <Input type="text" name="storeName" id="storeName" placeholder="Ingresa el nombre de la tienda" required />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="storeDescription" sm={2}>Descripción Tienda</Label>
                        <Col sm={10}>
                            <Input type="textarea" name="storeDescription" id="storeDescription" placeholder="Describe tu tienda" rows="3" required />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="phoneNumber" sm={2}>Celular</Label>
                        <Col sm={10}>
                            <Input type="tel" name="phoneNumber" id="phoneNumber" placeholder="Ingresa tu celular" required />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="address" sm={2}>Dirección</Label>
                        <Col sm={10}>
                            <Input type="text" name="address" id="address" placeholder="Ingresa una dirección" required />
                        </Col>
                    </FormGroup>
                    <Button type="submit" color="info" block>Registrarse</Button>
                </Form>
            </div>
        </div>
    )
}

export default SectionRegister;
