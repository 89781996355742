import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardHeader, CardBody, Col, Form, FormGroup, Input, Label, Row, FormText } from 'reactstrap';
import { addProducts } from 'services/productoAdminServices/productsServices';
import Notify from 'react-notification-alert';



const AddProductsComponent = ({refreshProducts}) => {
    const [product, setProduct] = useState({
        added_by: '',
        userId: '',
        name: '',
        slug: '',
        unit: '',
        min_qty: '',
        description: '',
        unit_price: '',
        stock_qty: ''
    });
    const [loading, setLoading]= useState(false);

    /* Manejo de notificaciones */
    const notifyRef = useRef(null);

    /* Notificacion advertencia */
    const showNotificationDanger = (message) => {
        notifyRef.current.notificationAlert({
            place: 'tc',
            message: message,
            type: 'danger',
            icon: 'bx bxs-bell bx-tada',
            autoDismiss: 5
        });
    };

    const showNotificationInfo = (message) => {
        notifyRef.current.notificationAlert({
            place: 'tc',
            message: message,
            type: 'info',
            icon: 'bx bxs-info-circle bx-tada',
            autoDismiss: 5
        });
    };


    /* Manejo de notificaciones */


    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value });
    };

    const handleFileChange = (e) => {
        // const { name, files } = e.target;
        // if (name === 'main_image') {
        //     setProduct({ ...product, main_image: files[0] });
        // } else {
        //     setProduct({ ...product, thumbnails: [...files] });
        // }
    };

    const generateSlugAndFillData = () => {
        let slug = product.name.trim().replace(/\s+/g, '-').toLowerCase();
        slug = slug.replace(/[^a-z0-9-]/g, '');
        console.log("Slug formado: ", slug);
        setProduct(prevProduct => ({ ...prevProduct, slug: slug, userId: localStorage.getItem('id_user') }));
        // Devolver una promesa resuelta
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (product.name) {
        //     generateSlugAndFillData(); // Esperar a que se complete la generación del slug y se llene la data
        // }
        setLoading(true);
        let data = {
            added_by: localStorage.getItem('usser'),
            userId: product.userId,
            name: product.name,
            slug: product.slug,
            unit: product.unit,
            min_qty: product.min_qty,
            description: product.description,
            unit_price: product.unit_price,
            stock_qty: product.stock_qty,
        }
        console.log("Producto prev load : ", data);
        if (!product.name || !product.slug || !product.unit || !product.min_qty || !product.description || !product.unit_price || !product.stock_qty) {
            showNotificationDanger("Por favor, complete todos los campos");
            setLoading(false);
        } else {
            try {
                const response = await addProducts(data);
                console.log(response.data);
                setLoading(false);
                limpiarCampos();
                showNotificationInfo("Producto agregado con exito!");
                refreshProducts();
            } catch (error) {
                setLoading(false);
                console.log(error);
                if (error.response.data.message) {
                    showNotificationDanger(error.response.data.message);
                }
                showNotificationDanger("Error subiendo el producto a la DB");
            }
        }
    };

    const limpiarCampos = () => {
        setProduct({
            added_by: '',
            userId: '',
            name: '',
            slug: '',
            unit: '',
            min_qty: '',
            description: '',
            unit_price: '',
            stock_qty: ''
        })
    }



    return (
        <div className='content'>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h2>CREACION DE PRODUCTOS</h2>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={handleSubmit}>
                                {/* TODO: ajustar para tener en cuenta que el agregado por hace referecnia a los tipos de usuarios 
                                        que hayan como por ejemplo ADMIN, SELLER
                                */}
                                {/* <FormGroup>
                                    <Label for="added_by">Agregado por</Label>
                                    <Input
                                        type="text"
                                        name="added_by"
                                        id="added_by"
                                        value={product.added_by}
                                        onChange={handleChange}
                                    />
                                </FormGroup> */}
                                {/* TODO: se agregaria el id del suario sea ADMIN o SELLER para asociar productos a un id de usuario */}
                                {/* <FormGroup>
                                    <Label for="user_id">ID Usuario</Label>
                                    <Input
                                        type="number"
                                        name="user_id"
                                        id="user_id"
                                        value={product.user_id}
                                        onChange={handleChange}
                                    />
                                </FormGroup> */}
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="name">Nombre del Producto</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={product.name}
                                                onChange={handleChange}
                                                onBlur={generateSlugAndFillData}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="slug">Slug</Label>
                                            <Input
                                                type="text"
                                                name="slug"
                                                id="slug"
                                                value={product.slug}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="unit">Unidad</Label>
                                            <Input
                                                type="text"
                                                name="unit"
                                                id="unit"
                                                value={product.unit}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="min_qty">Cantidad Mínima</Label>
                                            <Input
                                                type="number"
                                                name="min_qty"
                                                id="min_qty"
                                                value={product.min_qty}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="unit_price">Precio por Unidad</Label>
                                            <Input
                                                type="number"
                                                name="unit_price"
                                                id="unit_price"
                                                value={product.unit_price}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="stock_qty">Cantidad en Stock</Label>
                                            <Input
                                                type="number"
                                                name="stock_qty"
                                                id="stock_qty"
                                                value={product.stock_qty}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>


                                </Row>
                                <FormGroup>
                                    <Label for="description">Descripción</Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        id="description"
                                        value={product.description}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="main_image">Imagen Principal</Label>
                                        <Input
                                            type="file"
                                            name="main_image"
                                            id="main_image"
                                            onChange={handleFileChange}
                                        />
                                        <FormText color="muted">
                                            Sube una imagen principal del producto.
                                        </FormText>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="thumbnails">Thumbnails</Label>
                                        <Input
                                            type="file"
                                            name="thumbnails"
                                            id="thumbnails"
                                            multiple
                                            onChange={handleFileChange}
                                        />
                                        <FormText color="muted">
                                            Sube imágenes pequeñas (thumbnails) del producto.
                                        </FormText>
                                    </FormGroup>
                                </Col>

                                <Button type="submit" color="primary">{loading ? 'Cargando...': 'Agregar Producto'}</Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Notify ref={notifyRef} />
        </div>
    )
}

export default AddProductsComponent
