import axios from "axios";
import { urlBase } from "../defaultValues.js";



export const loginService = (body: { usernameOrEmail: string; password: string }) =>
    axios.post(`${urlBase}/auth`, body, {
        headers: {
            'Content-Type': 'application/json',
        }
    });