import axios from "axios";
import { urlBase } from "../../defaultValues";


export const addProducts = async (data) => {
    return await axios.post(`${urlBase}/admin/products/addProduct`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
        }
    });
}

export const getProductsByUserId = async () => {
    return await axios.get(`${urlBase}/admin/products/${localStorage.getItem('id_user')}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
        }
    });
}

export const deleteProductById = async (idProduct) => {
    return await axios.delete(`${urlBase}/admin/products/delete/${idProduct}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
        }
    });
}
