import React, { useEffect, useRef, useState } from 'react'
import { Card, CardHeader, FormGroup, Input, Label, Row, Table } from 'reactstrap'
import { Col } from 'reactstrap';
import { Button } from 'reactstrap';
import '../assets/css/black-dashboard-react-product-manage.css'
import AddProductsComponent from 'components/AddProductsComponent/AddProductsComponent';
import { getProductsByUserId } from 'services/productoAdminServices/productsServices';
import { deleteProductById } from 'services/productoAdminServices/productsServices';
import Notify from 'react-notification-alert';



const ProductManage = () => {
  const [productsTable, setProductTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);

  /* Manejo de notificaciones */
  const notifyRef = useRef(null);

  /* Notificacion advertencia */
  const showNotificationDanger = (message) => {
    notifyRef.current.notificationAlert({
      place: 'tc',
      message: message,
      type: 'danger',
      icon: 'bx bxs-bell bx-tada',
      autoDismiss: 5
    });
  };

  // Obtener los productos de la página actual
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = productsTable.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(productsTable.length / productsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getProductsaTable = async () => {
    try {
      const response = await getProductsByUserId();
      console.log(response);
      setProductTable(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  //FUNCION ELIMINAR PRODUCTO
  const deleteProduct = async (id) => {
    try {
      const response = await deleteProductById(id);
      console.log(response);
      showNotificationDanger("Producto eliminado exitosamente");
      refreshProducts();
    } catch (error) {
      console.log(error);
    }
  }

  const refreshProducts = () => {
    getProductsaTable();
  }

  useEffect(() => {
    getProductsaTable();
  }, []);
  return (
    <div className='content'>
      <Notify ref={notifyRef} />
      <Row>
        <Col xs="12">
          <Card className='card-chart'>
            <CardHeader>
              <Row>
                <Col className="text-left" sm="9">
                  <FormGroup className='searchBarProduct'>
                    <Input type="text" name="success" id="success" placeholder='Buscar por id o por nombre de producto' />
                  </FormGroup>

                </Col>
                {/* <Col className='text-right' sm="3">
                  <Button>Agregar producto</Button>
                </Col> */}

                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Nombre</th>
                      <th>Slug</th>
                      <th className="text-right">Status</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentProducts && currentProducts.map((product, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>{product.name}</td>
                        <td>{product.slug}</td>
                        <td className="text-center"><i className='btn-icon'>Activo</i></td>
                        <td className="text-right">
                          <Button className="btn-icon" color="info" size="sm">
                            <i className="fa fa-user"></i>
                          </Button>{` `}
                          <Button className="btn-icon" color="success" size="sm">
                            <i className="fa fa-edit"></i>
                          </Button>{` `}
                          <Button className="btn-icon" color="danger" size="sm" onClick={() => deleteProduct(product.id_producto)}>
                            <i className="fa fa-times" />
                          </Button>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </Table>
                <div className="pagination-controls">
                  <Button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</Button>
                  <span>Page {currentPage} of {totalPages}</span>
                  <Button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</Button>
                </div>
              </Row>
            </CardHeader>
          </Card>
        </Col>
      </Row>
      <Row>

      </Row>

      <AddProductsComponent refreshProducts={() => getProductsaTable()} />

    </div>
  )
}

export default ProductManage

