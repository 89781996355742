import React, { useEffect, useState } from "react";
import useForm from "./useForm";
import validate from "./LoginFormValidationRules";
import { Link } from "react-router-dom";
import { loginService } from "services/login.tsx";
import NavbarLogin from "components/navbarLogin/NavbarLogin";
import SectionRegister from "components/sectionRegister/SectionRegister";

const Form = props => {
    // const { values, errors, handleChange, handleSubmit } = useForm(
    //     login,
    //     validate
    // );
    const [usernameOrEmail, setUsernameOrEmail] = useState('');
    const [password, setPassword] = useState('')
    const [loggedIn, setLoggedIn] = useState(false);

    function onSubmit() {
        // setLoggedIn(true);
        // props.parentCallback(true);
        if (usernameOrEmail && password) {

            loginService({ usernameOrEmail, password }).then((res) => {
                console.log("Successful Login: ", res);
                if (res.data.data.role === 'ROLE_ADMIN' || res.data.data.role === 'ROLE_SELLER') {
                    window.location.href = '/admin/dashboard'
                    localStorage.setItem('id_user', res.data.data.id_usuario);
                    localStorage.setItem('usser', res.data.data.username);
                    localStorage.setItem('jwt_token', res.data.token);
                } else {
                    window.location.href = '/'
                }
            }).catch((err) => console.log(err));
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (usernameOrEmail, password) {
            onSubmit();
        } else {
            alert("La cagaste");
        }
    }
    useEffect(() => {
        console.log(usernameOrEmail);
        console.log(password);
    }, [usernameOrEmail, password]);

    return (
        <>
            <NavbarLogin />
            <SectionRegister />
        </>
    );
};

export default Form;
